<template>
  <v-dialog v-if="item != null" v-model="dialog" @click:outside="$emit('close', item)" max-width="650">
    <v-card>
      <v-card-title class="headline">Historial de Estados</v-card-title>

      <v-card-text>
        <template>
          <v-data-table :headers="headers" :items="item" :loading-text="$t('cargando_espere')" class="background-color: grey  lighten-3 w-100 mt-4">
            <v-spacer></v-spacer>
            <template v-slot:item="props">
              <tr>
                <td>
                  <p v-if="props.item.fecha">{{ new Date(props.item.fecha).toLocaleDateString() + " " + props.item.fecha.substr(11, 8) }}</p>
                </td>
                <td v-html="getState(props.item.estado.id)"></td>
                <td>#{{ props.item.numTrip }}</td>
                <td>{{ props.item.nameUser }}</td>
                <td>{{ props.item.typeUser }}</td>
              </tr>
            </template>
            <v-spacer></v-spacer>
          </v-data-table>
        </template>
      </v-card-text>
      <v-card-actions>
        <template>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="$emit('close', item)">
            Cerrar
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { remitosService } from "@/libs/ws/remitosService";

export default {
  props: {
    dialog: Boolean,
    loading: Boolean,
    item: Array,
    close: Function,
    headers: Array,
    updateDialog: Function,
  },
  data() {
    return {
      serviceRemitos: remitosService,
    };
  },

  watch: {
    dialog() {
      console.log(this.item, "abri");
    },
  },
  mounted() {},
  methods: {
    getState(estado) {
      return remitosService.getStatesHTML(estado);
    },
  },
};
</script>
