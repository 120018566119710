import { genericService } from "./genericService";

const section = "Vehicle";

function filter(name = " ", from = "0", limit = "10", active = "1") {
  return genericService.filter(section, "vehicles", name, from, limit, active);
}

function getAll() {
  return genericService.get("vehicle/ListAllVehicle");
}
function remove(id) {
  return genericService.remove(section, id);
}

function create(item) {
  const param = {
    Patent: item.patent,
    Type: item.type.id,
    MaxCharge: parseFloat(item.maxCharge),
    CompanyTransport: item.companyTransport.id,
    Capacity: parseFloat(item.capacity),
    Observation: item.observation,
    Satelital: item.satelital,
  };
  return genericService.create(section, param);
}

function update(item) {
  const param = {
    id: item.id,
    Patent: item.patent,
    Type: item.type.id,
    MaxCharge: parseFloat(item.maxCharge),
    CompanyTransport: item.companyTransport.id,
    Capacity: parseFloat(item.capacity),
    Observation: item.observation,
    Satelital: item.satelital,
    isActive: item.isActive,
  };
  return genericService.update(section, param);
}

export const vehiculoService = {
  getAll,
  filter,
  remove,
  create,
  update,
};
