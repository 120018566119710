import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import Login from "@/views/Login/Login.vue";
import Zones from "@/views/Zonas/Zonas.vue";
import TipoViaje from "@/views/TipoViaje/TipoViaje.vue";
import Origin from "@/views/Origen/Origen.vue";
import TipoContratacion from "@/views/TipoContratacion/TipoContratacion.vue";
import Transportation from "@/views/Transportes/Transportes.vue";
import Vehicles from "@/views/Vehiculos/index.vue";
import Loads from "@/views/Cargas/Cargas.vue";
import Payments from "@/views/Pagos/Pagos.vue";
import EmpresaSeguridad from "@/views/EmpresaSeguridad/EmpresaSeguridad.vue";
import PurchaseFees from "@/views/TarifaCompra/TarifaCompra.vue";
import Choferes from "@/views/Choferes/Choferes.vue";
import Clientes from "@/views/Clientes/index.vue";
import { authService } from "@/libs/ws/authService";
import Rutas from "@/views/Rutas/Rutas.vue";
import Monitoreo from "@/views/Monitoreo/Monitoreo.vue";
import MonitoreoDrivers from "@/views/MonitoreoDrivers/Monitoreo.vue";
import RutasDetalles from "@/views/Monitoreo/RutasDetalles.vue";
import Reportes from "@/views/Reportes/Reporte.vue";
import ReportesClientes from "@/views/ReportesClientes/Reporte.vue";
import Indicadores from "@/views/Indicadores/Indicadores.vue";
import Usuarios from "@/views/Usuarios/Usuarios.vue";
import Roles from "@/views/Roles/Roles.vue";
import Cluster from "@/views/Cluster/Cluster.vue";
import Warehouses from "@/views/Warehouse/Warehouse.vue";
import RateSale from "@/views/TarifaVenta/TarifaVenta.vue";
import Pedidos from "@/views/Pedidos/Pedidos.vue";
import Agenda from "@/views/Agenda/Agenda.vue";

//user Clients
import MonitoreoClients from "@/views_clients/Monitoreo/Monitoreo.vue";
import RemitosClient from "@/views_clients/Remitos/Remitos";
import RutasDetallesClients from "@/views_clients/Monitoreo/RutasDetalles.vue";
import Ruteo from "@/views/Ruteo/Ruteo.vue";
import Remitos from "@/views/Remitos/Remitos.vue";

//contenedores
import Contenedores_Monitoreo from "@/views_contenedores/Monitoreo/Monitoreo.vue";
import Contenedores_MonitoreoDrivers from "@/views_contenedores/MonitoreoDrivers/Monitoreo.vue";
import Contenedores_RutasDetalles from "@/views_contenedores/Monitoreo/RutasDetalles.vue";
import Contenedores_Rutas from "@/views_contenedores/Rutas/Rutas";
import Contenedores_Reportes from "@/views_contenedores/Reportes/Reporte.vue";

import Constant from "@/constants/";
import { cryptoUtils } from "@/libs/utils/cryptoUtils";

Vue.use(VueRouter);

const userType = cryptoUtils.decrypt(localStorage.getItem("external"), Constant.SECRET_PHRASE);

const adminRoutes = [
  { path: "/", redirect: "/monitoring" },

  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/clients",
    name: "Clientes",
    component: Clientes,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/calendar",
    name: "Agenda",
    component: Agenda,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/drivers",
    name: "Choferes",
    component: Choferes,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pedidos",
    name: "Pedidos",
    component: Pedidos,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/zones",
    name: "Zones",
    component: Zones,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/destinations",
    name: "Destinations",
    component: TipoViaje,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/origin",
    name: "Origin",
    component: Origin,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/warehouses",
    name: "Warehouses",
    component: Warehouses,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/trips",
    name: "Trips",
    component: TipoContratacion,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/transportation",
    name: "transportation",
    component: Transportation,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/loads",
    name: "loads",
    component: Loads,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/payments",
    name: "payments",
    component: Payments,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/security-companies",
    name: "security-companies",
    component: EmpresaSeguridad,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/vehicles",
    name: "vehicles",
    component: Vehicles,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/purchase-fees",
    name: "purchase-fees",
    component: PurchaseFees,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/rate-sale",
    name: "rate-sale",
    component: RateSale,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/routes",
    name: "Rutas",
    component: Rutas,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/monitoring",
    name: "Monitoreo",
    component: Monitoreo,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/drivermonitoring",
    name: "MonitoreoDrivers",
    component: MonitoreoDrivers,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/monitoring/route/:rutaid",
    name: "MonitoreoRutas",
    component: RutasDetalles,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reports",
    name: "Reportes",
    component: Reportes,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kpi",
    name: "KPI",
    component: Indicadores,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users",
    name: "Usuarios",
    component: Usuarios,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/roles",
    name: "Roles",
    component: Roles,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/deliverynotes",
    name: "Remitos",
    component: Remitos,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/routing",
    name: "Ruteo",
    component: Ruteo,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cluster",
    name: "Cluster",
    component: Cluster,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/containers/monitoring",
    name: "Monitoreo",
    component: Contenedores_Monitoreo,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/containers/drivermonitoring",
    name: "MonitoreoDrivers",
    component: Contenedores_MonitoreoDrivers,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/containers/monitoring/route/:rutaid",
    name: "MonitoreoRutas",
    component: Contenedores_RutasDetalles,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/containers/routes",
    name: "Rutas",
    component: Contenedores_Rutas,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/containers/reports",
    name: "Reporte",
    component: Contenedores_Reportes,
    meta: {
      requiresAuth: true,
    },
  },
];
const clienteRoutes = [

  { path: "/", redirect: "/monitoring" },
  {
    path: "/monitoring",
    name: "Monitoreo",
    component: MonitoreoClients,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/monitoring/route/:rutaid",
    name: "MonitoreoRutas",
    component: RutasDetallesClients,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/deliverynotes",
    name: "Remitos",
    component: RemitosClient,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/containers/monitoring",
    name: "Monitoreo",
    component: Contenedores_Monitoreo,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/containers/monitoring/route/:rutaid",
    name: "MonitoreoRutas",
    component: Contenedores_RutasDetalles,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reports",
    name: "ReportesClientes",
    component: ReportesClientes,
    meta: {
      requiresAuth: true,
    },
  },
];

const proveedorRoutes = [

  { path: "/", redirect: "/monitoring" },
  {
    path: "/monitoring",
    name: "Monitoreo",
    component: MonitoreoClients,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/routes",
    name: "Rutas",
    component: Rutas,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/containers/routes",
    name: "Rutas",
    component: Contenedores_Rutas,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/monitoring/route/:rutaid",
    name: "MonitoreoRutas",
    component: RutasDetallesClients,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/containers/monitoring",
    name: "Monitoreo",
    component: Contenedores_Monitoreo,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/containers/monitoring/route/:rutaid",
    name: "MonitoreoRutas",
    component: Contenedores_RutasDetalles,
    meta: {
      requiresAuth: true,
    },
  },
]

const publicRoutes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      guest: true,
    },
  },
]

//const routes = [];
let routes = [];
if (userType == Constant.USER_TYPE_INTERNAL) {
  routes = [...adminRoutes, ...publicRoutes];
} else if (userType == Constant.USER_TYPE_EXTERNAL_CLIENT) {
  routes = [...clienteRoutes, ...publicRoutes];
} else if (userType == Constant.USER_TYPE_EXTERNAL_PROVEEDOR) {
  routes = [...proveedorRoutes, ...publicRoutes];
} else {
  routes = [  { path: "/", redirect: "/login" }, ...publicRoutes];
}
const router = new VueRouter({
  routes,
});

//const auth = authService.isAuthenticated();

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    //if (!auth.loggedIn()) {
    if (!authService.isAuthenticated()) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    if (authService.isAuthenticated() && to.path == "/login") {
      next({
        path: "/",
      });
    } else {
      next(); // make sure to always call next()!
    }
  }
});

export default router;
