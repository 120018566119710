import { akeronService } from './akeronService'

function login(username,password) {
  const param = {
    "Username": username,
    "Password" : password
  }
  return akeronService.make_post_request('login/LoginWeb',param)  
}

function isAuthenticated(){
  return (
    localStorage.getItem('token') != undefined &&
    localStorage.getItem('expires') != undefined &&
    localStorage.getItem('username') != undefined &&    
    Date.now() < Date.parse(localStorage.getItem('expires'))
  )
}

function logOut(){    
  localStorage.removeItem('token') 
  localStorage.removeItem('expires') 
  localStorage.removeItem('username')  
  localStorage.removeItem("rol");
  localStorage.removeItem("external");
}

export const authService = {
  login,
  isAuthenticated,
  logOut
}
